// Entry point for the build script in your package.json
require("turbolinks").start()
require("@rails/activestorage").start()
require("./channels")
require('./mobile_apps/turbolinks_adapter')

import "./controllers"
import Rails from '@rails/ujs'

Rails.start()
